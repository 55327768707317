@import "../../../styles/index.scss";

.switch__wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	background: $darkBlue;
	border-radius: 48px;
	position: relative;
	transition: background-color 0.2s;
	padding: 3px;

	&:has(input:checked) {
		background: $violet;
	}

	input {
		display: none;

		&:checked {
			&+.switch-button {
				left: calc(100% - 3px);
				transform: translateX(-100%);
			}


		}
	}

	.switch-button {
		position: absolute;
		top: 3px;
		left: 3px;
		border-radius: 50%;
		transition: 0.2s;
		background: $primaryGradient;

		display: block;
		box-shadow: -3px 1px 4px rgba(181, 182, 22, 0.2);
	}
}