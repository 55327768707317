@import "../../../../../styles/index.scss";

.results__establishments {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: grid;
	grid-template-columns: 2fr 1.7fr;
	gap: 20px;

	.establishments__list {
		width: 100%;
		height: 100%;
		overflow: auto;
		padding-right: 30px;

		.results__total {
			font-weight: $font-weight-bold;
			font-size: 24px;
			opacity: 0.8;
			margin-bottom: 15px;
		}

		ul {
			width: 100%;

			.list__item {
				width: 100%;
				display: grid;
				align-items: center;
				grid-template-columns: 150px 1.5fr;
				gap: 20px;
				padding: 15px 0;
				border-bottom: 1px solid $darkBlue;

				&>img {
					width: 100%;
					height: 150px;
					object-fit: cover;
					border-radius: 13px;
				}

				.item__info {
					display: flex;
					flex-direction: column;

					header {
						display: flex;
						align-items: flex-start;
						justify-content: space-between;

						.btn__favourite {
							cursor: pointer;
							width: 20px;
							height: 16px;
							object-fit: contain;
						}

						.item__categories {
							display: flex;
							flex-wrap: wrap;
							margin: 0 10px 10px 0;

							span {
								text-transform: uppercase;
								font-weight: 500;
								font-size: 13px;
								margin-right: 8px;
								opacity: 0.8;
							}
						}

						&>img {
							width: 20px;
							height: 15px;
							object-fit: contain;
						}
					}

					.item__name {
						font-weight: $font-weight-medium;
						font-size: 20px;
						margin-bottom: 10px;
					}

					.item__address {
						margin-bottom: auto;

						* {
							font-size: 11px;
						}

						.address__details {
							opacity: 0.8;
						}

						.link__map {
							cursor: pointer;
							font-weight: $font-weight-medium;
							text-decoration-line: underline;
							color: #70a2fb;
						}
					}

					footer {
						margin-top: 25px;
					}
				}
			}
		}
	}

	.map__wrapper {
		height: 100%;
		width: 100%;
	}
}

.results-establishments__mobile {
	grid-template-columns: 1fr;
	gap: 20px;
	overflow: auto;

	.map__wrapper {
		order: -1;
		height: 215px;
	}

	.establishments__list {
		padding-right: 0;

		.results__total {
			width: 100%;
			text-align: center;
			opacity: 1;
			font-size: 22px;
			padding-bottom: 10px;
			border-bottom: 1px solid $darkBlue;
			margin-bottom: 0;

			&::before {
				content: "";
				width: 50px;
				height: 5px;
				background-color: #fff;
				display: block;
				margin: 0 auto 15px;
				border-radius: 6px;
			}
		}

		ul {
			.list__item {
				grid-template-columns: 1fr;
				padding: 25px 0;
			}
		}
	}
}

.results-establishments__favorites {
	grid-template-columns: 1.4fr 2fr;

	.establishments__list {
		ul {

			.list__item {
				grid-template-columns: 250px 1.5fr;

				&>img {
					height: 232px;
				}

			}
		}
	}
}

.loader__wrapper {
	width: 100%;

	@media (min-width: 481px) {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}