@import "../../../../../../../styles/index.scss";

.gallery-carousel__wrapper {
	position: fixed;
	z-index: 101;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(8, 7, 21, 0.9);
	padding: 50px 45px;

	.photo-slide {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
		display: flex;
		flex-direction: column;

		img {
			width: 80%;
			height: 60%;
			max-width: 895px;
			max-height: 488px;
			object-fit: contain;
			border-radius: 6px;
			margin-bottom: 50px;
		}

		p {
			font-size: 18px;
			font-weight: $font-weight-medium;
			text-align: center;
		}

		.photo__title {
			text-transform: uppercase;
			margin-bottom: 10px;
		}

		.photo__desc {
			opacity: 0.8;
		}
	}
}

.gallery-carousel__mobile {
	padding: 45px 0;

	&>header {
		position: absolute;
		z-index: 2;
		top: 42px;
		padding: 0 15px;
	}

	.photo-slide {
		img {
			width: 100%;
			height: 100%;
			max-width: 100%;
			max-height: 300px;
		}
	}
}