.page-wrapper {
	width: 100%;
	display: flex;
	height: 100%;
	overflow: auto;

	.section__wrapper {
		width: 100%;
		height: 100%;
		overflow: auto;
		padding: 100px 0 0;

		& > section {
			width: 100%;
		}
	}
}

.page-wrapper__no-nav {
	.section__wrapper {
		padding-top: 45px;
	}
}
