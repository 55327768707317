@import "../../../../styles/index.scss";

.auth__wrapper {
	background-color: $ebony;
	background-image: url("../../../../assets/images/auth-bg.png");
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 130px 0 25px;
	justify-content: space-between;
	overflow-y: auto;

	.logo__link {
		margin-bottom: 50px;

		img {
			width: 120px;
			height: auto;
			object-fit: contain;
		}
	}
}

.auth-wrapper__mobile {
	background-image: url("../../../../assets/images/auth-bg-mobile.png");
	background-position: bottom left;
	background-repeat: no-repeat;
	padding: 35px 15px;

	.logo__link {
		img {
			width: 36px;
			height: auto;
			object-fit: contain;
		}
	}
}
