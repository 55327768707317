@import "../../../../styles/index.scss";

.navigate-link {
	font-size: 17px;
	font-weight: $font-weight-light;
	display: flex;
	flex-wrap: wrap;

	a {
		font-size: 17px;
		font-weight: $font-weight-bold;
		text-underline-position: under;
		text-decoration-thickness: 1px;
	}
}
