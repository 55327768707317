@import "../../../../styles/index.scss";

.forgot-pwd__form {
	width: 100%;
	max-width: 315px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;

	header {
		p {
			font-size: 14px;
			opacity: 0.8;
			margin: 10px 0;
		}
	}

	.form__body {
		display: flex;
		flex-direction: column;
		align-items: center;

		.success__notification-card {
			width: 100%;
			max-width: 240px;
			background: $primaryTransparentGradient;
			border-radius: 20px;
			padding: 35px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin-bottom: 35px;
			text-align: center;

			img {
				height: 30px;
				width: auto;
				object-fit: contain;
			}

			h3 {
				margin: 15px 0;
				font-size: 22px;
			}

			p {
				font-size: 14px;
				opacity: 0.8;
			}
		}

		.success__text {
			font-size: 14px;
			opacity: 0.6;
			text-align: center;
		}
	}

	footer {
		margin-top: 30px;
		display: flex;
		flex-direction: column;
		align-items: center;

		p {
			margin-top: 25px;
			text-align: center;
			font-size: 11px;
			opacity: 0.6;
		}
	}

	.success__actions {
		button:first-child {
			margin-bottom: 25px;
		}
	}
}

.forgot-pwd-form__success {
	justify-content: flex-start;
}

.forgot-pwd-form__mobile {
	max-width: 100%;
	justify-content: flex-start;

	header {
		h2 {
			cursor: pointer;
			display: flex;
			justify-content: flex-start;
			align-items: center;

			img {
				width: 20px;
				height: auto;
				object-fit: contain;
				margin-right: 15px;
			}
		}

		p {
			margin: 15px 0 25px;
		}
	}

	.form__body {
		.success__text {
			max-width: 80%;
		}
	}

	footer {
		margin-top: 50px;

		p {
			max-width: 80%;
			margin-top: 55px;
			font-size: 13px;
		}
	}

	.success__actions {
		margin-top: 65px;
	}
}
