@import "../../../../../../../styles/index.scss";


.card-icon {
        width: min(40vw, 150px);
        height: min(40vw, 150px);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        gap: 15px;

        &:hover {
                cursor: pointer;

                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
        }

        h5 {
                font-size: 1.2rem;
                font-weight: 600;
        }
}

.card-icon__mbl {
        width: min(40vw, 100px);
        height: min(40vw, 100px);

        gap: 10px;

        h5 {
                font-size: 0.8rem;
        }
}