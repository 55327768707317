@import "../../../styles/index.scss";

.navbar {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 100;

	.navbar__menu {
		background-color: $black;
		width: 100%;
		max-width: 1270px;
		height: 100px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 20px;
	}

	.navbar__full {
		max-width: 100%;
		padding-left: 40px;
	}

	.menu__wrapper {
		display: flex;
		align-items: center;

		.nav__links {
			display: flex;
			width: auto;
			align-items: center;
			margin: 0 15px 0 20px;

			li {
				.link {
					display: flex;
					align-items: center;
					text-decoration: none;
					color: $white;

					.avatar {
						width: 20px;
						height: 20px;
						border-radius: 50%;
						object-fit: cover;
						margin-right: 5px;
					}

					.link__icon {
						height: 15px;
						width: auto;
						object-fit: contain;
						margin-right: 5px;
					}
				}

				.signup__link,
				.login__link {
					padding: 10px;
					width: 120px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 6px;
					text-decoration: none;
				}

				.login__link {
					background: $darkGradient;
				}

				.signup__link {
					background: $primaryGradient;
				}
			}

			li:not(:last-child) {
				margin-right: 15px;
			}

			.link__profile {
				margin-right: 30px;
			}
		}

		.nav__socials {
			display: flex;
			list-style: none;

			li {
				height: 32px;
				width: 32px;
				border-radius: 50%;
				background: $darkGradient;

				.social__link {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					height: 100%;

					.social__icon {
						height: 16px;
						width: 16px;
						object-fit: contain;
					}
				}
			}

			li:not(:last-child) {
				margin-right: 15px;
			}
		}
	}
}

.navbar__mobile {
	.navbar__menu {
		.nav__links {
			display: flex;
			width: auto;
			align-items: center;
			margin: 0;

			li {
				.link {
					display: flex;
					align-items: center;
					text-decoration: none;
					color: $white;

					.avatar {
						width: 32px;
						height: 32px;
						border-radius: 50%;
						object-fit: cover;
						margin-right: 5px;
					}

					.link__icon {
						height: 28px;
						width: auto;
						object-fit: contain;
						margin-right: 5px;
					}
				}
			}

			li:not(:last-child) {
				margin-right: 15px;
			}
		}
	}

	.navbar__centered {
		justify-content: center;
	}
}