@import "../../../styles/index.scss";

.loader {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: rgba(18, 17, 37, 0.7);
	top: 0;
	z-index: 10999999;

	.spinner__wrapper {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		margin: auto;

		.spinner {
			border: 6px solid $darkBlue;
			border-top: 6px solid $gray;
			border-radius: 50%;
			width: 60px;
			height: 60px;
			animation: spin 2s linear infinite;
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}
}
