@import "../../../../../styles/index.scss";

.search-panel {
	width: 100%;
	height: 100%;
	overflow: auto;
	display: flex;
	flex-direction: column;
	padding-right: 10px;

	.panel__title {
		font-size: 20px;
		margin-bottom: 15px;
	}

	.panel__categories {
		.category-item {
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 8px;
			padding: 8px 12px;
			margin: 3px;
			cursor: pointer;

			img {
				height: 20px;
				width: 20px;
				object-fit: contain;
				margin-right: 10px;
			}

			span {
				font-size: 15px;
				font-weight: 500;
			}
		}

		.category-item__active {
			img {
				filter: saturate(0) contrast(0) brightness(2);
			}
		}

		.categories-list {
			display: flex;
			flex-wrap: wrap;
		}
	}

	.filters__wrapper {
		.filters__list {
			h4 {
				font-size: 18px;
				font-weight: 500;
				margin-bottom: 10px;
			}

			ul {
				li label {
					display: flex;
					align-items: center;
					position: relative;
					cursor: pointer;

					span:not(.label) {
						margin-right: 10px;
						flex-shrink: 0;
					}

					.label {
						opacity: 0.8;
					}
				}

				li:not(:last-child) {
					margin-bottom: 10px;
				}
			}

			.filter__radius {
				width: 100%;

				input {
					overflow: visible;
					position: relative;
					-webkit-appearance: none;
					appearance: none;
					width: 100%;
					height: 4px;
					background: $gray;
					background-image: $primaryGradient;
					background-repeat: no-repeat;

					&::-webkit-slider-thumb {
						-webkit-appearance: none;
						appearance: none;
						height: 14px;
						width: 14px;
						border-radius: 50%;
						background: $primaryGradient;
						cursor: pointer;
						box-shadow: rgba(51, 98, 142, 0.3) 0px 0px 0px 8px, rgba(51, 98, 142, 0.3) 0px 0px 0px 14px;

						&:active {
							box-shadow: rgba(51, 98, 142, 0.4) 0px 0px 0px 10px, rgba(51, 98, 142, 0.3) 0px 0px 0px 15px;
							transition: box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, left 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
								bottom 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
						}
					}

					&::-webkit-slider-runnable-track {
						-webkit-appearance: none;
						appearance: none;
						box-shadow: none;
						border: none;
						background: none;
					}
				}

				p {
					width: 100%;
					display: flex;
					justify-content: space-between;
					margin-top: 5px;
					opacity: 0.8;
					font-weight: $font-weight-medium;
					font-size: 11px;
				}
			}
		}

		.filters__list:not(:last-child) {
			margin-bottom: 20px;
		}
	}
}

.search-panel__mobile {
	overflow: hidden;
	padding-right: 0;

	&>header {
		display: flex;
		padding-bottom: 35px;
		align-items: center;
		justify-content: space-between;

		.btn__settings {
			width: 24px;
			height: auto;
			cursor: pointer;

			svg {
				width: 100%;
				height: auto;
				object-fit: contain;
			}
		}

		.settings__open {
			svg {
				path {
					fill: url(#primary-gradient);
				}
			}
		}
	}

	.panel__categories {
		width: 100%;
		position: relative;

		.category-item {
			margin: 0;
		}
	}

	.filters__wrapper {
		position: relative;
		visibility: hidden;
		opacity: 0;
		top: -20px;
		height: 0;
	}

	.filters-wrapper__open {
		visibility: visible;
		opacity: 1;
		top: 0;
		height: 100%;
		transition: all 0.5s;
	}
}