@import "../../../styles/index.scss";

.primary-card {
	background: $darkBlue;
	border-radius: 20px;
	padding: 30px 20px;
	width: 141px;
	min-height: 141px;
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-shrink: 0;
	cursor: pointer;

	img {
		height: 41px;
		width: auto;
		object-fit: contain;
	}

	p {
		display: flex;
		height: 100%;
		align-items: center;
		justify-content: center;
		text-align: center;
		width: 100%;
		margin-top: 20px;
		font-size: 14px;
		font-weight: $font-weight-semi-bold;
		flex-wrap: wrap;
		word-break: break-word;
		white-space: break-spaces;
	}
}

.card__active {
	background: $primaryGradient;

	img {
		filter: saturate(0) contrast(2) brightness(2);
	}
}
