@import "../../../styles/index.scss";

.logo__link {
	display: flex;

	.logo {
		width: auto;
		object-fit: contain;
	}
}
