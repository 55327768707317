@import "../../../../styles/index.scss";

.signup__wrapper {
	width: 100%;
	height: 100%;
	overflow: auto;

	.signup__form {
		width: 100%;
		min-height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 50px 25px 25px;

		header {
			text-align: center;
			margin-bottom: 50px;

			p {
				margin: 15px 0 0;
				font-size: 24px;
				font-weight: $font-weight-medium;
				opacity: 0.8;
			}

			.title {
				font-size: 34px;
			}
		}

		.dots {
			display: flex;
			margin-bottom: 50px;

			.dot {
				width: 18px;
				height: 18px;
				border-radius: 50%;
				background: $darkBlue;
			}

			.dot:not(:last-child) {
				margin-right: 8px;
			}

			.dot__active {
				background: $violet;
			}
		}

		.form__body {
			width: 100%;
			display: flex;
			justify-content: center;
		}

		.facilities__list,
		.disabilities__list {
			width: 100%;
			display: flex;
			justify-content: center;
			flex-wrap: wrap;

			& > div {
				margin: 0 20px 40px;
			}
		}

		.check-result {
			width: 100%;
			margin-bottom: 55px;

			.values__wrapper {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: 120px;
				margin-bottom: 55px;
				justify-content: center;
				align-items: center;

				.result__values {
					display: flex;
					flex-wrap: wrap;
					align-items: center;

					& > div {
						margin: 10px;
					}
				}
			}

			.field__accompanied {
				display: flex;
				align-items: center;
				justify-content: center;

				p {
					font-weight: $font-weight-medium;
					font-size: 20px;
					margin-right: 35px;
				}

				.field__switch {
					display: flex;
					align-items: center;

					& > label {
						margin: 0 8px;
					}

					& > span {
						font-size: 13px;
						font-weight: $font-weight-medium;
					}
				}
			}
		}

		.form__personal-info {
			width: 100%;
			max-width: 310px;
		}

		footer {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-top: 35px;
			width: 100%;

			.terms__link {
				margin-bottom: 25px;
				font-size: 12px;
				a {
					font-size: inherit;
				}
			}

			button {
				max-width: 310px;

				img {
					max-height: 13px;
				}
			}

			button:not(:last-child) {
				margin-bottom: 25px;
			}
		}

		.forgot-pwd__link {
			text-decoration: underline;
			font-size: 16px;
			text-align: right;
			cursor: pointer;
		}

		.errorMsg {
			width: 100%;
			color: $red;
			display: flex;
			justify-content: center;
			font-size: 12px;
			line-height: 1.2;
			margin-bottom: 20px;
		}
	}
}

.signup__wrapper {
	.signup-form__mobile {
		margin: 0;
		padding: 40px 15px;

		header {
			margin-bottom: 30px;
			text-align: left;

			.logo__link {
				text-align: center;
				display: block;
				margin-bottom: 50px;

				img {
					width: 36px;
					height: auto;
					object-fit: contain;
				}
			}

			h1,
			h2 {
				cursor: pointer;
				display: flex;
				justify-content: flex-start;
				align-items: center;

				img {
					width: 20px;
					height: auto;
					object-fit: contain;
					margin-right: 15px;
				}
			}

			h2 {
				align-items: flex-start;

				img {
					padding-top: 5px;
				}
			}

			p {
				font-size: 13px;
				font-weight: 500;
			}

			.title {
				font-size: 22px;
			}
		}

		.facilities__list,
		.disabilities__list {
			& > div {
				margin: 0 20px 30px;
			}
		}

		.check-result {
			width: 100%;
			margin-bottom: 55px;

			.values__wrapper {
				gap: 45px;
			}

			.field__accompanied {
				flex-direction: column;
				align-items: flex-start;

				p {
					margin: 0 35px 15px 0;
				}
			}
		}

		.form__personal-info {
			max-width: 100%;
		}

		footer {
			button {
				max-width: 100%;

				img {
					max-height: 13px;
				}
			}

			button:not(:last-child) {
				margin-bottom: 15px;
			}
		}
	}
}
