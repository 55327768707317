@import "./modules/colors.scss";
@import "./modules/plugins.scss";
@import "./modules/mixins.scss";
@import "./modules/variables.scss";
@import "./modules/reset.scss";

* {
	font-family: $font-family;
	font-style: normal;
	font-weight: $font-weight-normal;
	font-size: $base-font-size;
	line-height: $base-line-height;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	background-color: $black;
}

.container {
	background-color: $black;
	height: 100vh;
	overflow: hidden;
	display: flex;
	flex-shrink: 1;
}

h1,
h2,
h3,
h4,
h5 {
	color: $white;
	font-weight: $font-weight-medium;
}

h1,
.h1 {
	font-size: 45px;
	font-weight: $font-weight-bold;

	@media (max-width: 480px) {
		font-size: 28px;
	}
}

h2,
.h2 {
	font-size: 28px;
}

h3,
.h3 {
	font-size: 20px;
}

h4,
.h4 {
	font-size: 16px;
	font-weight: $font-weight-medium;
}

h5,
.h5 {
	font-size: 14px;
	font-weight: $font-weight-medium;
}

b,
strong {
	font-weight: $font-weight-bold;
}

button {
	outline: none;
	cursor: pointer;
}

span,
p,
b,
strong,
div {
	color: $white;
}

.eye-icon {
	width: 20px;
	height: auto;
	object-fit: contain;
}

.hr-divider {
	border: 1px solid $gray;
	border-bottom: none;
	margin: 20px 0;
}

input[type="radio"],
input[type="checkbox"] {
	display: none;

	&:checked~.checkmark {
		background: $primaryGradient;
		border: none;
	}
}

.checkmark {
	position: relative;
	width: 15px;
	height: 15px;
	border-radius: 5px;
	border: 1px solid rgba(255, 255, 255, 0.5);
	color: $white;
}

hr {
	border: 1px solid $darkBlue;
	border-bottom: none;
	margin: 25px 0;
}

.row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.col {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}