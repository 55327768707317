@import "../../../styles/index.scss";

.upload-file__wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-top: 20px;
	margin-bottom: 40px;

	& > button {
		margin-top: 30px;
		margin-left: auto;
	}

	.upload-file__btn {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		cursor: pointer;
		border-radius: 10px;
		margin-bottom: 15px;

		.btn__label {
			padding: 10px;
			width: 100%;
			height: 100%;
			cursor: pointer;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			img {
				display: block;
				height: 30px;
				margin-bottom: 15px;
			}

			span {
				font-size: 15px;
			}
		}

		.error {
			font-size: 15px;
			margin-top: 15px;
			color: $red;
		}
	}

	.preview__text {
		color: $gray;
		text-transform: uppercase;
		padding-left: 10px;
		margin: 10px 0;
		font-size: 15px;
	}

	.upload-image__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		height: 100%;

		.upload-image {
			width: 100%;
			height: 100%;
			max-height: 150px;
			border-radius: 3px;
			object-fit: cover;
		}

		.upload-image__profile {
			width: 80px;
			height: 80px;
			object-fit: cover;
			border-radius: 50%;
		}

		.remove__btn {
			padding: 5px 0;
			cursor: pointer;
			display: flex;
			align-items: center;
			margin-top: 15px;
			font-size: 15px;
			color: $red;

			img {
				width: auto;
				height: 20px;
				object-fit: contain;
				margin-right: 5px;
			}
		}
	}
}

.drag-container {
	height: 100px;
	width: 100%;
	border: 1px solid $gray;
	border-radius: 10px;
	display: flex;
}

.overlay {
	border-color: $red;
}

.upload-file__mobile {
	.upload-image__wrapper {
		.upload-image__profile {
			width: 150px;
			height: 150px;
		}

		.remove__btn {
			font-size: 17px;
		}
	}
}
