@import "../../../../styles/index.scss";

.success-page__wrapper {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 415px;

	.success__notification-card {
		width: 100%;
		height: 100%;
		max-width: 415px;
		max-height: 335px;
		background: $darkGradient;
		border-radius: 20px;
		padding: 35px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-bottom: 50px;
		text-align: center;

		img {
			height: 45px;
			width: auto;
			object-fit: contain;
		}

		h3 {
			margin: 25px 0;
		}

		p {
			font-size: 18px;
			opacity: 0.8;
		}
	}

	& > button {
		max-width: 315px;
	}
}

.success-page__mobile {
	.success__notification-card {
		max-width: 240px;
		max-height: 195px;
		padding: 25px;

		h3 {
			margin: 15px 0;
			font-size: 22px;
		}

		p {
			font-size: 14px;
		}
	}

	& > button {
		margin-top: auto;
		max-width: 100%;
	}
}
