@import "../../../../styles/index.scss";

.primary__btn {
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;

	& > span {
		font-size: inherit;
		font-weight: $font-weight-bold;
		color: inherit;
		line-height: 1;
		text-underline-position: under;
		text-decoration-thickness: 1px;
	}

	.left-icon {
		margin-right: 10px;
		height: 100%;
		width: auto;
		object-fit: contain;
	}

	.right-icon {
		margin-left: 10px;
		height: 100%;
		width: auto;
		object-fit: contain;
	}

	&:disabled {
		opacity: 0.5;
	}
}
