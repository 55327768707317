@import "../../../styles/index.scss";

.map__container {
	position: relative;

	.switch__wrapper {
		position: absolute;
		bottom: 23px;
		right: 60px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
		background: #fcfcfc;
		border-radius: 100px;
		transition: background-color 0.2s;
		padding: 3px;
		width: 84px;
		height: 40px;

		&::after,
		&::before {
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 18px;
			height: 18px;
			object-fit: contain;
		}

		&::before {
			content: "";
			left: 21px;
		}

		&::after {
			content: url("../../../assets/svg/moon.svg");
			right: 3px;
		}

		input {
			display: none;

			&:checked {
				& + .switch-button {
					left: calc(100% - 3px);
					transform: translateX(-100%);
				}
			}
		}

		.switch-button {
			position: absolute;
			top: 3px;
			left: 3px;
			border-radius: 50%;
			width: 36px;
			height: 36px;
			transition: 0.2s;
			background: #0a0919;
			display: block;
			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				width: 18px;
				height: 18px;
				object-fit: contain;
				path {
					stroke: $white;
				}
			}
		}
	}

	.switch__checked {
		&::before {
			content: url("../../../assets/svg/sun.svg");
		}

		&::after {
			content: "";
		}
	}

	.switch__mobile {
		left: 5px;
		margin-top: 10px;
		width: 64px;
		height: 30px;
		padding: 2px;

		&::before {
			left: 16px;
		}

		&::after {
			right: 2px;
		}

		input {
			&:checked {
				& + .switch-button {
					left: calc(100% - 2px);
				}
			}
		}

		.switch-button {
			top: 2px;
			left: 2px;
			width: 26px;
			height: 26px;
		}
	}
}

.map__marker {
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: 0px 0px 23px 7px rgba(46, 51, 56, 0.5);

	svg {
		width: 37px;
		height: 46px;
		object-fit: contain;
		margin-bottom: 5px;

		path {
			fill: var(--fillColor);
		}
	}

	.marker__name {
		letter-spacing: 0;
		font-size: 12px;
		text-align: center;
		font-weight: $font-weight-medium;
	}
}

.marker__active {
	box-shadow: 0px 0px 40px 12px rgba(8, 7, 21, 0.7);

	svg {
		transform: scale(1.1);
	}
}
