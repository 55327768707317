@import "../../../../styles/index.scss";

.login__form {
	width: 100%;
	max-width: 315px;
	display: flex;
	flex-direction: column;

	.login__error {
		color: $red;
		font-size: 14px;
		line-height: 1.2;
		margin-bottom: 20px;
		text-align: center;
	}

	header {
		margin-bottom: 30px;
	}

	footer {
		display: flex;
		flex-direction: column;
		align-items: center;

		a {
			font-weight: $font-weight-normal;
			font-size: 11px;
			letter-spacing: 0.01em;
			margin-bottom: 25px;
			opacity: 0.8;
		}
	}

	.errorMsg {
		display: flex;
		font-size: 12px;
		line-height: 1.2;
	}
}

.nav__links {
	margin-top: 50px;
	max-width: 315px;

	p {
		text-align: center;

		span {
			font-size: 11px;
			opacity: 0.6;
		}

		a {
			font-size: 12px;
			font-weight: $font-weight-medium;
		}
	}

	p:first-child {
		margin-bottom: 55px;
	}
}

.login-form__mobile {
	max-width: 100%;

	header {
		text-align: left;

		h2 {
			cursor: pointer;
			display: flex;
			justify-content: flex-start;
			align-items: center;

			img {
				width: 20px;
				height: auto;
				object-fit: contain;
				margin-right: 15px;
			}
		}
	}

	footer {
		a {
			margin: 30px 0 50px;
		}
	}
}

.nav-links__mobile {
	max-width: 190px;

	p:first-child {
		margin-bottom: 25px;
	}
}