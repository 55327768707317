@import "../../../../../styles/index.scss";

.establishment__details {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: grid;
	grid-template-columns: 4fr 1.5fr;
	gap: 25px;
	position: relative;

	.details__info {
		width: 100%;
		height: 100%;
		overflow: auto;
		padding-right: 10px;

		header {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;

			.btn__favourite {
				cursor: pointer;
				width: 20px;
				height: 16px;
				object-fit: contain;
			}

			.item__categories {
				display: flex;
				flex-wrap: wrap;
				margin: 0 10px 10px 0;

				span {
					text-transform: uppercase;
					font-weight: 500;
					font-size: 13px;
					margin-right: 8px;
					opacity: 0.8;
				}
			}

			&>img {
				width: 20px;
				height: 15px;
				object-fit: contain;
			}
		}

		.item__name {
			font-size: 20px;
			font-weight: $font-weight-medium;
			opacity: 0.8;
			margin-bottom: 10px;
		}

		.item__address {
			margin-bottom: 10px;

			* {
				font-size: 14px;
			}

			.address__details {
				opacity: 0.8;
			}

			.link__map {
				cursor: pointer;
				font-weight: $font-weight-medium;
				text-decoration-line: underline;
				color: #70a2fb;
			}
		}

		.details__lists {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			li,
			.contacts__item {
				font-weight: 500;
				font-size: 14px;
				margin-bottom: 8px;
				opacity: 0.8;
			}

			.trovera-list {
				li {
					&::before {
						content: "\2713";
						font-size: 12px;
						margin-right: 3px;
					}
				}
			}

			.contacts {
				display: flex;
				flex-direction: column;

				.contacts__item {
					display: flex;
					align-items: center;

					img {
						width: 11px;
						height: auto;
						object-fit: contain;
						margin-right: 5px;
					}
				}
			}
		}

		.item__gallery {
			display: grid;
			grid-template-columns: 1.5fr 1fr;
			gap: 8px;
			height: 275px;
			width: 100%;
			margin-bottom: 25px;

			img {
				border-radius: 3px;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			.cover-image__wrapper {
				width: 100%;
				height: 275px;
			}

			.gallery__images {
				width: 100%;
				height: 275px;
				display: grid;
				grid-template-rows: repeat(2, 1fr);
				gap: 8px;

				.gallery__second-row,
				.gallery__first-row {
					height: 100%;
					width: 100%;
					display: grid;
					gap: 8px;

					img {
						max-height: 134px;
					}
				}

				.gallery__first-row {
					grid-template-columns: 1fr 2fr;
				}

				.gallery__second-row {
					grid-template-columns: 2fr 1fr;
				}
			}
		}

		.gallery__details {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			gap: 8px;

			&>img {
				width: 100%;
				height: 100%;
				max-height: 135px;
				object-fit: cover;
				border-radius: 3px;
			}
		}

		.open-slider__btn {
			height: 100%;
			width: 100%;
			border-radius: 3px;
			background-color: $darkBlue;
			text-decoration: underline;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 20px;
			font-weight: $font-weight-medium;
			padding: 10px;
			text-align: center;
			cursor: pointer;
		}

		.item__description {
			opacity: 0.8;
			margin-bottom: 25px;

			p {
				margin-top: 8px;
				font-size: 15px;
			}
		}
	}

	.map__wrapper {
		height: 100%;
		width: 100%;
	}
}

.establishment-details__mobile {
	grid-template-columns: 1fr;
	overflow: initial;

	.gallery-carousel__mobile {
		margin-bottom: 25px;
		height: 215px;
		width: 100%;

		.photo-slide {
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 3px;
			}
		}
	}

	.details__info {
		.details__lists {
			flex-direction: column;

			&>* {
				border-bottom: 1px solid $darkBlue;
				padding-bottom: 10px;
				margin-bottom: 15px;
			}
		}

		.item__gallery {
			grid-template-columns: 1fr;
			height: auto;

			.gallery__images {
				grid-template-rows: auto;
				height: auto;
			}
		}

		.gallery__details {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	.map__wrapper {
		height: 215px;
	}
}
.back__arrow{
	cursor: pointer;
	margin-bottom: 20px;
}