@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

// FONT
$font-family: "Manrope", "Open Sans", "Roboto", sans-serif;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 600;
$font-weight-semi-bold: 700;
$font-weight-bold: 800;
$base-font-size: 15px;

$base-line-height: 1.2;
