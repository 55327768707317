@import "../../../styles/index.scss";

.field__wrapper {
	margin-bottom: 10px;
	position: relative;
	width: 100%;

	&:has(.field__input:disabled) {
		background-color: #8b8b8b33;
		border-radius: 10px;
	}

	.field__textarea,
	.input-group {
		width: 100%;
		margin-bottom: 10px;
		color: $white;

	}

	.input-group {
		display: flex;
		align-items: center;
	}

	.field__textarea,
	.field__input {
		width: 100%;
		border: none;
		color: $white;
		font-weight: $font-weight-bold;

		&:focus-visible {
			outline: none;
		}

		&::placeholder {
			color: $white;
			opacity: 0.6;
		}

		&:disabled {
			color: $white;
			font-weight: $font-weight-bold;
			opacity: 0.8;
		}
	}

	.field__textarea {
		resize: vertical;
		min-height: 50px;
		padding-top: 15px !important;
	}

	.field__textarea:disabled {
		resize: none;

	}

	.field__label {
		display: block;
		margin-bottom: 10px;
		font-weight: 700;
		font-size: 12px;
	}

	.error {
		display: flex;
		color: $red;
		font-size: 12px;
		line-height: 1.2;
		margin-bottom: 10px;
	}

	.error-textarea,
	.error-field {
		color: $red;
	}
}

.full-height {
	height: 100%;

	.field__textarea {
		height: calc(100% - 24px);
	}
}

input[type="password" i] {
	color: $white !important;
}

input[type="password" i].error-field {
	color: $red !important;
}

.input-group {
	display: flex;
	padding-right: 10px;
}

.show-pwd-icon {
	cursor: pointer;

	path {
		fill: $white;
	}
}

.show-pwd-icon__red {
	path {
		fill: $red;
	}
}