@import "../../../styles/index.scss";

.establishments__section {
	width: 100%;
	height: 100%;
	max-height: 100vh;
	display: grid;
	grid-template-columns: minmax(250px, 1fr) 5fr;
	gap: 65px;
	padding: 50px 0 25px 45px;
}

.establishments-section__favorites {
	display: flex;
}

.establishments-section__mobile {
	max-height: none;
	height: auto;
	overflow: auto;
	grid-template-columns: 1fr;
	gap: 40px;
	padding: 0 15px 15px;
}