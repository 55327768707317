@import "../../../../../../../styles/index.scss";




.modal__wrapper {

        .container {
                width: 100%;
                height: 100%;
                display: grid;
                display: flex;
                gap: 43px;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 0 10%;
                background: transparent;

                .container__grid {
                        width: 80%;
                        height: 100%;
                        display: grid;
                        grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
                        grid-gap: 29px;
                        height: 50%;
                        justify-content: center;
                        align-items: center;
                }

        }
}

.modal__wrapper__mbl {

        .container {
                width: 100%;
                height: 100%;
                display: grid;
                display: flex;
                gap: 43px;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 0 10%;
                background: transparent;
                padding: 15px;

                .container__grid {
                        width: 100%;
                        height: 100%;
                        display: grid;
                        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
                        grid-gap: 10px;
                        height: 90%;
                        overflow-y: scroll;
                }

        }
}