@import "../../../styles/index.scss";

.modal__wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	backdrop-filter: blur(5px);
	background-color: #00000055;
	z-index: 111;
}

.modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-height: 100%;
	box-shadow: 0px 0px 23px 7px rgba(46, 51, 56, 0.25);

	.modal__title {
		width: 100%;
		text-align: center;
		font-size: 20px;
		font-weight: 800;
		font-family: 'Manrope', 'Trebuchet MS', sans-serif;
		height: 50px;

	}

	.modal__header {
		left: 15px;
		top: 15px;
		z-index: 20;
		cursor: pointer;
		font-size: 28px;
		font-weight: 800;
		font-family: Calibri, 'Trebuchet MS', sans-serif;

		:hover {
			cursor: pointer;
		}
	}
}