@import "./colors.scss";
@import "./variables.scss";

/*-----------------------------------------------
|   Date Picker
-----------------------------------------------*/
.react-datepicker__input-container {
	width: 100%;
	display: flex;
	align-items: center;
	border-radius: 14px;
	border: 1px solid #fff3;
	height: 56px;
	padding: 18px 20px;
	background: transparent;
	margin-bottom: 10px;

	input {
		font-size: 13px;
		font-weight: $font-weight-bold;
		color: $white;
		background: transparent;
		border: none;
		outline: none;

		&:focus-visible {
			outline: none;
		}

		&::placeholder {
			color: $white;
			opacity: 0.6;
			font-weight: $font-weight-bold;
		}

		&:disabled {
			color: $white;
			font-weight: $font-weight-bold;
		}
	}
}
.react-datepicker-popper {
	width: 100%;
	max-width: 310px;
	border-radius: 14px;
	padding-top: 0 !important;
}
.react-datepicker {
	width: 100%;
	max-width: 310px;
	height: 100%;
	background-color: $black !important;
	border: 1px solid #fff3 !important;
	box-shadow: 4px 0px 20px 7px rgba(8, 7, 21, 0.7);
	border-radius: 14px !important;
	padding: 10px 20px;

	@media (max-width: 480px) {
		width: 100%;
		min-width: initial;
	}

	.react-datepicker__triangle {
		display: none;
	}

	.react-datepicker__navigation {
		top: 10px;
	}

	.react-datepicker__navigation--previous {
		left: calc(100% - 68px);
	}
	.react-datepicker__navigation-icon::before {
		border-color: $violet;
	}
	.react-datepicker__navigation:hover .react-datepicker__navigation-icon::before {
		border-color: $violet;
	}

	.react-datepicker__year-read-view,
	.react-datepicker__month-read-view {
		background-color: $black;
		padding: 5px 35px 5px 10px !important;
		color: $white;
		font-size: 13px;
		font-weight: $font-weight-medium;
		border-radius: 6px;
		border: 1px solid #fff3 !important;
		visibility: visible !important;

		.react-datepicker__year-read-view--down-arrow,
		.react-datepicker__month-read-view--down-arrow {
			right: 10px !important;
			top: 30%;
		}
	}
	.react-datepicker__year-dropdown,
	.react-datepicker__month-dropdown {
		background-color: $black !important;
		border-radius: 14px;
		border: 1px solid #fff3 !important;
		top: 70%;
		left: 0;
		padding: 10px 5px;

		.react-datepicker__year-option,
		.react-datepicker__month-option {
			font-weight: $font-weight-medium;
			padding: 5px 0 !important;

			&:hover {
				background-color: $violet;
			}
		}
	}

	.react-datepicker__year-dropdown {
		left: 50%;
		transform: translateX(-50%);
	}

	.react-datepicker__month-container {
		width: 100%;
		background-color: $black;
		padding: 0;

		.react-datepicker__header {
			background-color: $black !important;
			border: none;
			margin-bottom: 10px;
			text-align: left;

			.react-datepicker__current-month {
				color: $white;
				font-weight: $font-weight-bold;
				font-size: 13px;
				margin-bottom: 10px;
				text-transform: capitalize;
			}

			.react-datepicker__day-names {
				width: 100%;
				display: flex;
				justify-content: space-between;

				.react-datepicker__day-name {
					width: 30px;
					line-height: 30px;
					color: $white;
					font-weight: $font-weight-bold;
					font-size: 13px;
					text-transform: uppercase;
				}
			}
		}

		.react-datepicker__week {
			width: 100%;
			display: flex;
			justify-content: space-between;

			.react-datepicker__day {
				width: 30px;
				line-height: 30px;
				color: $white;
				font-weight: $font-weight-bold;
				font-size: 13px;
			}
		}

		.react-datepicker__week:not(:last-child) {
			margin-bottom: 10px;
		}
	}

	.react-datepicker__day--selected,
	.react-datepicker__day--in-selecting-range,
	.react-datepicker__day--in-range,
	.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
	.react-datepicker__day--selected,
	.react-datepicker__day--keyboard-selected,
	.react-datepicker__day:hover {
		background-color: $violet;
		border-radius: 50%;
		color: $white !important;
		font-weight: $font-weight-bold;
	}

	.react-datepicker__day--keyboard-selected:hover {
		background-color: $violet !important;
	}

	.react-datepicker__month--selecting-range
		.react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range),
	.react-datepicker__month--selecting-range
		.react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range),
	.react-datepicker__month--selecting-range
		.react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range),
	.react-datepicker__month--selecting-range
		.react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range) {
		background-color: rgba(123, 66, 255, 0.2);
	}

	.react-datepicker__day--in-selecting-range,
	.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
		background-color: rgba(123, 66, 255, 0.8);
	}

	.react-datepicker__day--disabled {
		opacity: 0.3;
	}

	.react-datepicker__day--disabled:hover {
		background-color: rgba(123, 66, 255, 0.2);
	}
}

/*-----------------------------------------------
|   Google Maps Control text
-----------------------------------------------*/
.gmnoprint > div,
.gm-svpc,
.gm-fullscreen-control,
.gm-style-mtc button {
	color: #0a0a19 !important;
	background-color: #fcfcfc !important;
	border-radius: 10px !important;

	ul li {
		color: #0a0a19 !important;
		background-color: #fcfcfc !important;
	}
}

.gmnoprint .gm-style-cc,
.gm-style-cc div,
.gmnoprint.gm-style-cc div,
.gmnoprint .gm-style-cc div {
	background-color: transparent !important;
}

.gmnoprint a,
.gm-style-cc a,
.gmnoprint button {
	color: $white !important;
}

.gm-style a[title~="Google"] {
	display: block;
	margin: 0 0 10px 0 !important;
}

.gm-style-mtc {
	&:not(:last-child) {
		margin-right: 10px;
	}
	ul li {
		display: flex !important;
		align-items: center !important;
		color: $darkBlue !important;

		span span {
			margin-right: 5px !important;
		}
	}
}

.gm-style-mtc button[aria-checked="false"] {
	color: $darkBlue !important;
}

/*-----------------------------------------------
|   Swiper
-----------------------------------------------*/

.swiper-prev-btn,
.swiper-next-btn {
	width: 60px;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	z-index: 10;
	cursor: pointer;

	& > svg {
		height: 60px;
		width: 60px;
	}
}

.swiper-prev-btn {
	left: 0;
}

.swiper-next-btn {
	transform: rotate(180deg);
}

.swiper-button-disabled {
	opacity: 0.5;
	cursor: default;
}

.swiper-prev-btn-hidden,
.swiper-next-btn-hidden {
	display: none;
}

.swiper-pagination {
	top: 30px;
	font-size: 18px;
	font-weight: $font-weight-medium;
	display: flex;
	justify-content: center;

	@media (max-width: 480px) {
		top: 0;
	}

	span {
		font-size: inherit;
		font-weight: inherit;
	}
}

.swiper-pagination-custom {
	right: 10%;
	width: auto !important;
	justify-content: flex-end;

	.custom-swiper-pagination {
		margin-top: auto;
		padding: 4px 8px;
		background-color: $black;
		margin-right: 5px;
		font-weight: $font-weight-bold;
		font-size: 11px;
		border-radius: 3px;
	}
}
