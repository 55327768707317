@import "../../../styles/index.scss";

.home-page {
	min-height: 100%;
	width: 100%;
	max-width: 1100px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 50px 0;

	.home__header {
		text-align: center;
		margin-bottom: 55px;

		h1 {
			display: flex;
			align-items: center;
			margin-bottom: 15px;
		}

		h3 {
			opacity: 0.8;
		}
	}

	.home__content {
		width: 100%;

		.content__cards {
			width: 100%;
			display: flex;
			justify-content: center;
			flex-wrap: wrap;

			&>div {
				margin: 0 20px 40px;
			}
		}
	}
}

.home-page__mobile {
	padding: 50px 15px;

	.home__header {
		h3 {
			font-size: 13px;
		}
	}

	.home__content {
		width: 100%;

		.content__cards {
			width: 100%;

			&>div {
				margin: 0 10px 25px;
			}
		}
	}

	.links {
		margin-top: 75px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;

		.auth__links {
			margin-bottom: 75px;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;

			.signup__link,
			.login__link {
				padding: 12px;
				width: 100%;
				max-width: 200px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 6px;
				text-decoration: none;
				font-size: 15px;
			}

			.login__link {
				background: $darkGradient;
				margin-bottom: 25px;
			}

			.signup__link {
				background: $primaryGradient;
			}
		}

		.social__links {
			display: flex;
			justify-content: center;
			width: 100%;

			.social__link {
				height: 32px;
				width: 32px;
				border-radius: 50%;
				background: $darkGradient;
				display: flex;
				align-items: center;
				justify-content: center;

				.social__icon {
					height: 16px;
					width: 16px;
					object-fit: contain;
				}
			}

			.social__link:not(:last-child) {
				margin-right: 15px;
			}
		}
	}
}