@import "../../../../styles/index.scss";

.forgot-pwd__form {
	width: 100%;
	max-width: 655px;
	display: flex;
	flex-direction: column;
	padding: 25px;
	margin: 0px auto; // Center horizontally

	@media (max-width: 768px) { // Adjust for phones
		// margin: 50%; // Center horizontally
		position: relative;
		bottom:150px !important;
	}
	header {
		p {
			margin: 15px 0 50px;
			font-size: 17px;
			font-weight: 600;
			opacity: 0.5;
		}
	}

	.form__body {
		.title__italic {
			display: flex;
			align-items: center;
			font-style: italic;

			img {
				height: 24px;
				width: auto;
				object-fit: contain;
				margin-right: 15px;
			}
		}

		.text__normal {
			max-width: 450px;
			margin-top: 25px;
			font-size: 17px;
			margin-bottom: 80px;
		}

		.instruction {
			margin: 15px 0 50px;
			max-width: 300px;
			ul {
				margin-bottom: 40px;

				* {
					font-size: 17px;
				}
				p {
					opacity: 0.5;
					font-weight: $font-weight-medium;
				}

				li {
					list-style: none;
				}
			}

			& > p {
				font-size: 17px;
			}
		}
	}

	footer {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		margin-top: 10px;

		a {
			font-weight: $font-weight-normal;
			font-size: 15px;
			margin-bottom: 55px;
		}
	}

	.footer__left {
		align-items: flex-start;
	}

	.forgot-pwd__link {
		text-decoration: underline;
		font-size: 16px;
		text-align: right;
		cursor: pointer;
	}

	.errorMsg {
		color: $red;
		display: flex;
		font-size: 12px;
		line-height: 1.2;
	}
}

.forgot-pwd-form__mobile {
	display: flex;
	flex-direction: column;
	align-items: center; // Center horizontally on mobile
	justify-content: center; // Center vertically on mobile
	padding: 40px 25px;
	height: 100vh; // Full viewport height for vertical centering

	@media (max-width: 300px) {
		padding: 15px;
	}

	.logo__link {
		margin: 0 auto 50px;

		.logo {
			width: 80px;
			height: auto;
			object-fit: contain;
		}
	}

	header {
		text-align: center;
		margin-top: auto;

		p {
			font-size: 13px;
			margin: 15px auto 40px;

			span {
				font-size: inherit;
			}
		}
	}

	.form__body {
		margin-bottom: auto;
	}

	footer {
		margin-top: 40px;
		margin-bottom: auto;

		& > button {
			margin: 0 auto;
		}
	}

	.nav-link__wrapper {
		margin-top: 50px;
		display: flex;
		justify-content: center;

		p,
		a {
			font-size: 13px;
		}
	}
}
