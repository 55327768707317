@import "../../../styles/index.scss";

.select {
        width: max-content;
        min-width: 200px;
        margin-left: 1px;

        .select__control {
                background-color: $darkBlue !important;
                border: none;
                padding: 5px 10px;
                color: $white;
                font-size: 14px;
                font-weight: $font-weight-semi-bold;
                cursor: pointer;
                display: flex;
                border: none;
                border-radius: 10px 10px;
                transition-duration: 1s;
                animation-duration: 1s;

                &:hover {
                        background-color: $darkBlue;
                        border-radius: 10px 10px 0px 0px;
                }

                .select__single-value {
                        color: $white;
                        font-size: 14px;
                        font-weight: 700;

                }

        }

        .select__menu {
                background-color: $darkBlue;
                border: none;
                border-radius: 0px 10px;

                .select__option {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        background-color: $darkBlue;
                        color: $white;
                        min-height: 50px;
                        font-size: 14px;
                        font-weight: 700;
                        padding: 5px 20px;
                        text-align: left;

                        .select__option__text {
                                position: relative;

                                .select__option__cooming {
                                        position: absolute;
                                        top: -10px;
                                        right: 30px;
                                        background-color: orange;
                                        border-radius: 20px;
                                        padding: 2px 14px;
                                        font-size: 10px;
                                        font-weight: 500;
                                        width: fit-content;
                                }
                        }


                        &:hover {
                                cursor: pointer;
                                background: $primaryGradient ;
                        }
                }
        }
}