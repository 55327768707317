$ebony: #080715;
$black: #121125;
$white: #ffffff;
$darkBlue: #203150;
$violet: #7a42ff;
$primaryGradient: linear-gradient(232.71deg, #0eedff -23.85%, #ab74f9 109.37%);
$primaryTransparentGradient: linear-gradient(232.71deg, #0eedff33 -23.85%, #ab74f933 109.37%);
$darkGradient: linear-gradient(232.71deg, rgba(14, 237, 255, 0.2) -23.85%, rgba(171, 116, 249, 0.2) 109.37%);
$red: #d60078;
$gray: #cfd1d5;
