@import "../../../../../styles/index.scss";

.profile {
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;

}

.profile::before {
        content: "";
        opacity: 0.2;
        position: absolute;
        height: 200%;
        width: 200%;
        top: 20%;
        left: 0%;
        transform: rotate(-0deg);
        background: url("../../../../../assets/images/background.png") no-repeat;
        z-index: 1;
}

.profile__content {
        max-width: 400px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        z-index: 2;

        .profile__back {
                display: flex;
                align-items: center;
                gap: 10px;
                cursor: pointer;

                h1 {
                        color: $violet;
                        font-size: 28px;
                }
        }

        .profile__image {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 20px;
                cursor: pointer;

                img {
                        width: 88px;
                        height: 88px;
                        border-radius: 50%;
                        object-fit: cover;
                }
        }

        .profile__form__box {
                display: flex;
                flex-direction: column;
                gap: 30px;
                cursor: pointer;

                .facilities__list,
                .disabilities__list {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        flex-wrap: wrap;

                        &>div {
                                margin: 0 20px 40px;
                        }
                }

                .profile__form__edit {
                        margin-left: auto;

                        span {
                                text-decoration: underline;
                        }
                }

                .profile__form__select {
                        display: flex;
                        gap: 20px;
                        width: 100%;
                        height: max-content;
                        align-items: center;
                        justify-content: center;
                        flex-direction: row;
                        padding: 20px 0px;
                }

                .profile__form__buttons {
                        margin-top: 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        width: 100%;
                        gap: 20px;
                }

        }
}


.profile__mbl {
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;

        .profile__content {
                .profile__back {

                        h1 {}
                }

                .profile__image {

                        img {}
                }

                .profile__form__box {

                        .profile__form__edit {

                                span {}
                        }

                        .profile__form__select {}

                        .profile__form__buttons {
                                flex-direction: column;
                        }

                }
        }
}